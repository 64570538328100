var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('heade'),_c('div',{staticClass:"com",style:({
      backgroundImage: 'url(' + require('@/assets/' + _vm.backgroud) + ')',
    })},[_c('div',{staticClass:"com_box"},[_c('div',{staticClass:"com-left"},[_c('div',{staticClass:"com-left-top title",class:[
            _vm.titleLength < 10 || (_vm.titleLength > 10 && _vm.titleLength < 15)
              ? 'title-height-small'
              : '',
            _vm.titleLength == 10 ? 'title-height-middle' : '',
            _vm.titleLength == 15 ? 'title-height-small-middle' : '',
            _vm.titleLength >= 16 && _vm.titleLength < 20 ? 'title-height-big' : '',
            _vm.titleLength >= 20 ? 'title-height-larger' : '',
          ]},[_vm._v(" "+_vm._s(_vm.traditionCourse.name)+" ")]),_c('div',{staticClass:"com_title_box"},[_c('div',{staticClass:"com_title nice-scroll"},_vm._l((_vm.traditionCourse.division_category),function(items,indexs){return _c('div',{key:indexs,staticClass:"com_title_list"},[_c('div',{staticClass:"com-left-foot"},[_c('div',{},_vm._l((items.division),function(itemc,indexc){return _c('div',{key:indexc,attrs:{"id":itemc.division_content_id},on:{"click":function($event){return _vm.getTraditionDivisionContentById(
                        itemc.division_content_id,
                        itemc.charge_mode,
                        indexc
                      )}}},[(indexc == 0 && _vm.content == '')?_c('div',{directives:[{name:"trigger",rawName:"v-trigger"}],attrs:{"id":'trigger' + _vm.index}},[_c('div',{staticClass:"com-left-foot-top",class:[
                          _vm.queryWhere.id == itemc.division_content_id
                            ? 'addclass'
                            : '',
                        ]},[_vm._v(" "+_vm._s(itemc.name[0])+" ")]),_c('div',{staticClass:"com-left-foot-but",class:_vm.queryWhere.id == itemc.division_content_id
                            ? 'addclass'
                            : ''},[_vm._v(" "+_vm._s(itemc.name[1])+" ")])]):_vm._e(),_c('div',[_c('div',{staticClass:"com-left-foot-top",class:[
                          _vm.queryWhere.id == itemc.division_content_id
                            ? 'addclass'
                            : '',
                        ]},[_vm._v(" "+_vm._s(itemc.name[0])+" ")]),_c('div',{staticClass:"com-left-foot-but",class:_vm.queryWhere.id == itemc.division_content_id
                            ? 'addclass'
                            : ''},[_vm._v(" "+_vm._s(itemc.name[1])+" ")])])])}),0)])])}),0)])]),_c('div',{staticClass:"com-right"},[_c('el-button',{attrs:{"type":"text","icon":"el-icon-arrow-left"},on:{"click":function($event){return _vm.$router.back(-1)}}},[_vm._v("返回上一页")]),_c('div',{staticClass:"nice-scroll content",domProps:{"innerHTML":_vm._s(_vm.content)}})],1),_c('div',{staticClass:"com-btt"},[_vm._l((_vm.traditionCourses.slice(8, 16)),function(itemd,indexd){return _c('div',{key:indexd},[_c('router-link',{class:itemd.id == _vm.traditionCourse.id
                ? 'com-bbt-item active'
                : 'com-bbt-item',attrs:{"to":{
              path: _vm.pathUrl,
              query: { id: itemd.id },
            }}},[_vm._v(_vm._s(itemd.name))])],1)}),_vm._l((_vm.traditionCourses.slice(0, 8)),function(itemd,indexd){return _c('div',{key:indexd + '1'},[_c('router-link',{class:itemd.id == _vm.traditionCourse.id
                ? 'com-bbt-item active'
                : 'com-bbt-item',attrs:{"to":{
              path: _vm.pathUrl,
              query: { id: itemd.id },
            }}},[_vm._v(_vm._s(itemd.name))])],1)})],2)])]),_c('foot')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }