<template>
  <div>
    <heade></heade>
    <div
      class="com"
      :style="{
        backgroundImage: 'url(' + require('@/assets/' + backgroud) + ')',
      }"
    >
      <div class="com_box">
        <div class="com-left">
          <div
            class="com-left-top title"
            :class="[
              titleLength < 10 || (titleLength > 10 && titleLength < 15)
                ? 'title-height-small'
                : '',
              titleLength == 10 ? 'title-height-middle' : '',
              titleLength == 15 ? 'title-height-small-middle' : '',
              titleLength >= 16 && titleLength < 20 ? 'title-height-big' : '',
              titleLength >= 20 ? 'title-height-larger' : '',
            ]"
          >
            {{ traditionCourse.name }}
          </div>
          <div class="com_title_box">
            <div class="com_title nice-scroll">
              <div
                v-for="(items, indexs) in traditionCourse.division_category"
                :key="indexs"
                class="com_title_list"
              >
                <div class="com-left-foot">
                  <div class="">
                    <div
                      v-for="(itemc, indexc) in items.division"
                      :key="indexc"
                      @click="
                        getTraditionDivisionContentById(
                          itemc.division_content_id,
                          itemc.charge_mode,
                          indexc
                        )
                      "
                      :id="itemc.division_content_id"
                    >
                      <div
                        v-if="indexc == 0 && content == ''"
                        :id="'trigger' + index"
                        v-trigger
                      >
                        <div
                          class="com-left-foot-top"
                          :class="[
                            queryWhere.id == itemc.division_content_id
                              ? 'addclass'
                              : '',
                          ]"
                        >
                          {{ itemc.name[0] }}
                        </div>
                        <div
                          class="com-left-foot-but"
                          :class="
                            queryWhere.id == itemc.division_content_id
                              ? 'addclass'
                              : ''
                          "
                        >
                          {{ itemc.name[1] }}
                        </div>
                      </div>

                      <div>
                        <div
                          class="com-left-foot-top"
                          :class="[
                            queryWhere.id == itemc.division_content_id
                              ? 'addclass'
                              : '',
                          ]"
                        >
                          {{ itemc.name[0] }}
                        </div>
                        <div
                          class="com-left-foot-but"
                          :class="
                            queryWhere.id == itemc.division_content_id
                              ? 'addclass'
                              : ''
                          "
                        >
                          {{ itemc.name[1] }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="com-right">
          <el-button
            type="text"
            icon="el-icon-arrow-left"
            @click="$router.back(-1)"
            >返回上一页</el-button
          >
          <div class="nice-scroll content" v-html="content"></div>
        </div>
        <div class="com-btt">
          <div
            v-for="(itemd, indexd) in traditionCourses.slice(8, 16)"
            :key="indexd"
          >
            <router-link
              :to="{
                path: pathUrl,
                query: { id: itemd.id },
              }"
              :class="
                itemd.id == traditionCourse.id
                  ? 'com-bbt-item active'
                  : 'com-bbt-item'
              "
              >{{ itemd.name }}</router-link
            >
          </div>
          <div
            v-for="(itemd, indexd) in traditionCourses.slice(0, 8)"
            :key="indexd + '1'"
          >
            <router-link
              :to="{
                path: pathUrl,
                query: { id: itemd.id },
              }"
              :class="
                itemd.id == traditionCourse.id
                  ? 'com-bbt-item active'
                  : 'com-bbt-item'
              "
              >{{ itemd.name }}</router-link
            >
          </div>
        </div>
      </div>
    </div>
    <foot></foot>
  </div>
</template>
<script>
import "@css/ico.css";
import "@css/sanji.css";
import "@css/foot.css";
import $ from "jquery";
import $store from "../../store";
import heade from "@components/heade.vue";
import foot from "@components/foot.vue";
import { scroll } from "@utils/scroll";
import {
  gettradition,
  getTraditionCourseDivisionContent,
  gettraditionCourses,
} from "@api/public";
export default {
  components: {
    heade,
    foot,
  },
  props: {},
  data() {
    return {
      traditionCourse: {},
      traditionCourses: [],
      queryWhere: {},
      content: "",
      backgroud: "",
      pathUrl: "",
      titleLength: 0,
    };
  },
  mounted: function () {
    let that = this;
    gettraditionCourses().then(function (res) {
      that.$set(that, "traditionCourses", res.data);
    });

    gettradition(that.queryWhere).then(function (res) {
      that.$set(that, "traditionCourse", res.data);
      that.$set(that, "content", res.data.content);
      console.log(that.content);
    });
  },
  created: function () {
    this.queryWhere = this.$route.query;
    document.title = this.$route.meta.title;
    this.pathUrl = this.$route.meta.path;
    this.backgroud = this.$route.meta.background;
  },

  watch: {
    $route(to, from) {
      this.$router.go(0);
    },
    traditionCourse() {
      this.$nextTick(function () {
        scroll($(".nice-scroll"));
      });
    },
  },
  methods: {
    getTraditionDivisionContentById: function (id, charge_mode, index) {
      if (
        charge_mode == 1 ||
        ($store.state.app.userInfo != null &&
          $store.state.app.userInfo.is_empower)
      ) {
        let that = this;
        that.queryWhere = {};
        that.queryWhere.id = id;
        getTraditionCourseDivisionContent(that.queryWhere).then(function (res) {
          that.$set(that, "content", res.data.content);
        });
        let top = document.getElementsByClassName("content")[0].scrollTop;
        const timeTop = setInterval(function () {
          document.getElementsByClassName("content")[0].scrollTop = top -= 50;
          if (top <= 0) {
            clearInterval(timeTop);
          }
        }, 10);
      } else {
        if (!$store.state.app.token) {
          layui.use(["layer"], function () {
            var layer = layui.layer;
            layer.open({
              title: "",
              content: "您还未登录！是否去登录？",
              btn: ["是", "否"],
              closeBtn: 0,
              skin: "layui-layer-molv",
              yes: function () {
                window.location.href = "/login";
              },
            });
          });
        } else if (!$store.state.app.userInfo.is_empower) {
          layui.use(["layer"], function () {
            var layer = layui.layer;
            layer.open({
              title: "",
              content:
                "该部分内容为课题实验校、示范区会员账号专属内容，如需加入课题实验，请点击下方“课题申报”链接，按照要求提交信息，经课题组审核批准后，方可成为课题实验校、示范区，获得专属课题账号，正常查看全部内容。",
              closeBtn: 0,
              btn: ["课题申报", "关闭"],
              success: function (layero) {
                var btn = layero.find(".layui-layer-btn");
                btn.find(".layui-layer-btn0").attr({
                  href: "/application/shenbaobiao",
                });
              },
            });
          });
        }
      }
    },
  },
  updated: function () {
    this.titleLength = $(".title").text().length;
  },
  destroyed: function () {
    $(".nice-scroll").getNiceScroll().hide();
    $(".nicescroll-cursors").hide();
    $(".nicescroll-rails").remove();
  },
  directives: {
    trigger: {
      inserted(el, binging) {
        el.click();
      },
    },
  },
};
</script>
<style scoped>
.com {
  width: 100%;
  min-width: 1484px;
  height: 1660px;
  display: flex;
  flex-wrap: wrap;
  background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  background-position: center 0;
}
.com_box {
  width: 1484px;
  height: 1660px;
  margin: 0 auto;
  position: relative;
}
.com_title_box {
  width: 250px;
  height: 410px;
}

.com-btt {
  width: 861px;
  left: 268px;
}

.com-btt > div {
  width: 15px;
  writing-mode: vertical-lr;
  text-align: center;
}
.com-left-foot {
  display: flex;
  flex-direction: column;
}
.active {
  color: #900c0c;
}
.com_right {
  overflow-y: auto;
}
.addclass {
  color: #d8c8a5;
}
.nice-scroll {
  overflow-y: auto;
  height: 95%;
  padding-right: 20px;
}

.com-left-top {
  font-size: 26px;
}
.com-bbt-item {
  line-height: 26px;
}
.el-button--text {
  color: rgb(121, 0, 0);
}
</style>
